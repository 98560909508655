<template>
  <div class="bg-box">
    <img style="width: 100%" src="../../assets/img/banner.png" alt="" />
    <div class="w">
      <div class="works-top">
        <div class="title">
          <div></div>
          <div>兼职测试</div>
        </div>
      </div>

      <div class="cousre-name-tag">
        <div
          class="tag-item"
          v-for="(item, index) in navList"
          :key="index"
          @click="handletabTop(item.id,item.sign)"
        >
          <div :class="{ blue: id == item.id }">{{ item.title }}</div>
          <i v-show="id == item.id" class="el-icon-caret-bottom"></i>
        </div>
      </div>

      <div class="transverse"></div>
      <div class="no-list" v-show="list.length == 0">暂无测试题..</div>
      <div class="test-box" v-for="(ele, i) in list" :key="i">
        <img class="leimg" :src="ele.imgurl" alt="" />
        <div class="btn" @click="handleGoTest(ele)">开始测试</div>
      </div>
    </div>

    <div style="height: 200px"></div>
    <div class="dialog-rule">
      <el-dialog
        :show-close="false"
        :visible.sync="centerDialogVisible"
        width="1000px"
        center
      >
        <div class="popup-box">
          <div class="title-pupop">
            考试注意事项
            <img
              @click="centerDialogVisible = false"
              src="../../assets/img/del-popup.png"
              alt=""
            />
          </div>
          <div class="popup-content" v-html="info.content">
            <!-- {{ info.content }} -->
          </div>
          <div class="popup-btn" @click="eventclick()">进入测试</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { get_course_class, get_part_time } from "../../assets/api/index";
import { getStore, removeStore } from "../../assets/js/utils";
export default {
  data() {
    return {
      centerDialogVisible: false,
      navList: [],
      list: [],
      info: {},
      id: "",
      sign:''
    };
  },
  // created() {
  //   // console.log(document.links)
  //   document.title='123'
  //   var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  //   console.log(link)
  //   link.href = 'http://www.stackoverflow.com/favicon.ico';
  // },
  async mounted() {
    if (!getStore("token")) {
      this.$router.push({ path: "/login" });
    } else {
      await this.getclassList();
      this.id = this.navList[0].id;
      this.sign = this.navList[0].sign;
      await this.getPartTime();
    }
  },
  methods: {
    // 切换
    handletabTop(id,sign) {
      this.id = id;
      this.sign = sign
      this.getPartTime();
    },
    // 获取兼职测试
    async getPartTime() {
      let params = {
        course_category_id: this.id,
        sign:this.sign
      };
      let res = await get_part_time(params);
      if (res.code==200) {
        this.list = res.data ? res.data : [];
      }
    },
    // 去测试
    eventclick() {
      this.$router.push({ 
        path: `/test/${this.info.id}`,
        query:{
          sign:this.info.sign
        }
      });
    },
    handleGoTest(c) {
      this.info = c;
      if (c.test_pass == 1) {
        // 已通过
        this.$message("您的选择题已答完");
        this.$router.push({ path: `/actual-operation/${c.test_record_id}` });
      } else if (c.today_test_count >= 2) {
        this.$message.error("您今天已经测试了2次,次数已用完");
      } else {
        this.centerDialogVisible = true;
      }
    },
    // 获取分类
    async getclassList() {
      let params = {

      };
      let res = await get_course_class(params);
      if (res.code == 200) {
        this.navList = res.data ? res.data : [];
      }
    },
  },
};
</script>

<style scoped>
.test-box {
  /* background-color: #fff; */
  margin-top: 40px;
  overflow: hidden;
}
.popup-btn {
  width: 300px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #f38c45;
  font-size: 20px;
  margin: 20px auto 50px;
  border-radius: 20px;
  cursor: pointer;
  color: #fff;
}
.popup-box {
  border-radius: 2px;
  overflow: hidden;
}
.popup-content {
  width: 100%;
  height: 560px;
  overflow-y: auto;
  padding: 20px 40px;
  /* line-height: 20px; */
  box-sizing: border-box;
}

.title-pupop {
  height: 60px;
  text-align: center;
  width: 100%;
  background-color: #eb7b29;
  color: #fff;
  font-size: 20px;
  line-height: 60px;
  position: relative;
}
.title-pupop img {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 15px;
  width: 20px;
  height: 20px;
}
.btn {
  width: 264px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  border-radius: 25px;
  margin: 40px auto;
  background-color: #fe8a31;
  cursor: pointer;
}
.btn:hover {
  opacity: 0.7;
}
.leimg {
  width: 100%;
}
.works-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.title {
  display: flex;
  align-items: center;
}
.title div:nth-child(1) {
  width: 8px;
  height: 24px;
  background-color: #fbad51;
}
.title div:nth-child(2) {
  font-size: 20px;
  color: #000;
  margin-left: 10px;
  font-weight: 600;
}
.cousre-name-tag {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}
.tag-item {
  width: 286px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 18px;
  height: 120px;
  cursor: pointer;
}
.tag-item:nth-child(4n) {
  margin-right: 0;
}
.tag-item div {
  width: 286px;
  height: 80px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 80px;
  text-align: center;
  font-size: 15px;
  color: #545454;
}
.tag-item i {
  font-size: 30px;
  position: relative;
  bottom: 11px;
  color: #fbad51;
}
.blue {
  background-color: #fbad51 !important;
  color: #fff !important;
}
.transverse {
  width: 100%;
  height: 1px;
  background-color: #d6d6d6;
}
</style>